@font-face {
  font-family: "SFProDisplay-Black";
  src: local("SFProDisplay-Black"),
    url("./fonts/SFProDisplay-Black.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SFProDisplay-Bold";
  src: local("SFProDisplay-Bold"),
    url("./fonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SFProDisplay-Regular";
  src: local("SFProDisplay-Regular"),
    url("./fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SFProDisplay-Semibold";
  src: local("SFProDisplay-Semibold"),
    url("./fonts/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "MonoLisa-Bold";
  src: local("MonoLisa-Bold"),
    url("./fonts/MonoLisa-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "MonoLisa-Regular";
  src: local("MonoLisa-Regular"),
    url("./fonts/MonoLisa-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "MonoLisa-RegularItalic";
  src: local("MonoLisa-RegularItalic"),
    url("./fonts/MonoLisa-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

